<template>
  <div class="recordDetail">
    <div class="answerRecord-c">
      <div class="title">
        <div class="title-l">你的测试结果是</div>
        <div :class="['title-r', 'ty' + this.answerData.quScore]">
          {{ resultArrMap[this.answerData.quScore] }}
        </div>
      </div>
      <div class="answerRecord-main">
        <div class="item te">
          <div class="item-l">题目</div>
          <div class="item-c">正确答案</div>
          <div class="item-r">我的答案</div>
        </div>
        <div v-for="(item, index) in oList" :key="index" class="item">
          <div class="item-l">{{ item.title }}</div>
          <div class="item-c">
            {{
              returnTxt(item.answer, item.rightAnswer, item.optionList)
                .answerTxt
            }}
          </div>
          <div
            :class="['item-r', { mistake: item.rightAnswer != item.answer }]"
          >
            {{
              returnTxt(item.answer, item.rightAnswer, item.optionList)
                .rightAnswerTxt
            }}
          </div>
        </div>
      </div>
      <!-- <div class="btn" v-if="this.answerData.quScore == 1" @click="toReply">
        重新答题
      </div> -->
    </div>
    <div class="dialog" v-if="isDialog">
      <div class="dialog-c">
        <div class="close" @click="toClose">
          <img src="./img/close.png" alt="" />
        </div>
        <template v-if="!isVideo">
          <div class="title">学习视频</div>
          <div class="title">可获得再次答题的机会</div>
          <div class="btn" @click="lookVideo">查看视频</div>
          <div class="bottom"><img src="./img/back.png" alt="" /></div>
        </template>
        <template v-if="isVideo">
          <div class="video">
            <video
              id="video"
              :src="indexData.videoUrls"
              controls="controls"
            ></video>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";
import { rubTopicInfoUrl, recordDetailUrl, updateVideoUrl } from "./api.js";
import { resultArrMap } from "./map.js";
var moment = require("moment");
export default {
  name: "recordDetail",
  data() {
    return {
      id: "",
      topicId: "",
      resultArrMap,
      isDialog: false,
      answerData: {},
      oList: [],
      indexData: {},
      isVideo: false,
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.topicId = this.$route.query.topicId;
  },
  mounted() {
    // gloabalCount("", 20, 1);
    let _this = this;
    this.getAnswerData();
  },
  methods: {
    returnTxt(answer, rightAnswer, optionList) {
      if (answer && rightAnswer && optionList) {
        let answerArr = answer.split(",");
        var answerTxt = "";
        let rightAnswerArr = rightAnswer.split(",");
        var rightAnswerTxt = "";
        for (var i = 0; i <= optionList.length - 1; i++) {
          for (var j = 0; j <= answerArr.length - 1; j++) {
            if (optionList[i].optionKey == answerArr[j]) {
              answerTxt += optionList[i].optionTitle;
            }
          }
          for (var h = 0; h <= rightAnswerArr.length - 1; h++) {
            if (optionList[i].optionKey == rightAnswerArr[h]) {
              rightAnswerTxt += optionList[i].optionTitle;
            }
          }
        }
      }
      return {
        answerTxt: answerTxt,
        rightAnswerTxt: rightAnswerTxt,
      };
    },
    async updateVideo() {
      let params = {
        houseId: this.roomId,
        topicId: this.id,
        userId: this.userId,
      };
      let res = await this.$axios.post(
        `${updateVideoUrl}`,
        this.$qs.stringify(params)
      );
      if (res.code == 200) {
        this.getIndexData();
      }
    },
    lookVideo() {
      this.$router.push({
        name: "studyVideo",
        query: {
          oFrom: "isRecord",
        },
      });
    },
    async getIndexData() {
      let res = await this.$axios.get(rubTopicInfoUrl, {
        params: {
          houseId: this.roomId,
          userId: this.userId,
        },
      });
      if (res.code === 200) {
        this.indexData = res.data;
      } else {
      }
    },
    async toReply() {
      this.isDialog = true;
    },
    async getAnswerData() {
      let res = await this.$axios.get(recordDetailUrl, {
        params: {
          houseId: this.roomId,
          userId: this.userId,
          topicId: this.topicId,
          id: this.id,
          quCount: 3,
        },
      });
      if (res.code === 200) {
        this.answerData = res.data;
        this.oList = this.answerData.questionList;
      } else {
      }
    },
    toClose() {
      this.isDialog = false;
      this.isVideo = false;
    },
  },
};
</script>
<style lang="less" scoped>
.recordDetail {
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .answerRecord-c {
    padding: 0 30px 166px;
    box-sizing: border-box;
    .title {
      display: flex;
      align-items: center;
      padding: 60px 0 46px;
      .title-l {
        font-size: 28px;
        font-weight: 400;
        color: #525252;
        line-height: 40px;
        margin-right: 12px;
      }
      .title-r {
        font-size: 40px;
        font-weight: 600;
        color: #f6bf34;
        line-height: 56px;
        &.ty0 {
          color: #e50112;
        }
        &.ty1 {
          color: #e50112;
        }
        &.ty2 {
          color: #f8cc5c;
        }
        &.ty3 {
          color: #40a739;
        }
      }
    }
    .answerRecord-main {
      .item {
        display: flex;
        align-items: stretch;

        margin-bottom: 2px;
        .item-l {
          width: 302px;
          padding: 28px 0 28px 18px;
          background: #ebf1fa;
          font-size: 28px;
          font-weight: 400;
          color: #1a1c34;
          line-height: 40px;
        }
        .item-c {
          margin: 0 2px;
        }
        .item-c,
        .item-r {
          text-align: center;
          position: relative;
          font-size: 28px;
          font-weight: 400;
          color: #525252;
          line-height: 40px;
          flex: 1;
          background: #ebf1fa;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .mistake {
          color: #d53737;
        }
      }
      .te {
        .item-l,
        .item-c,
        .item-r {
          background: #cbdffc;
          font-size: 28px;
          font-weight: 400;
          color: #525252;
          line-height: 40px;
        }
      }
    }
    .btn {
      width: 598px;
      height: 80px;
      position: fixed;
      bottom: 76px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 99;
      background: #2e5da2;
      border-radius: 40px;
      font-size: 40px;
      text-align: center;
      font-weight: 400;
      color: #ffffff;
      line-height: 80px;
    }
  }
  .dialog {
    background: rgba(0, 0, 0, 0.85);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    .dialog-c {
      width: 646px;
      height: 494px;
      background: #ffffff;
      border-radius: 14px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding-top: 108px;
      box-sizing: border-box;
      .close {
        width: 44px;
        height: 44px;
        position: absolute;
        top: 30px;
        right: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .video {
        width: 100%;
        height: 300px;
        video {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        text-align: center;
        font-size: 36px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 50px;
      }
      .btn {
        width: 246px;
        height: 80px;
        background: #2e5da2;
        border-radius: 40px;
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        color: #ffffff;
        line-height: 80px;
        margin: 52px auto 40px;
      }
      .bottom {
        width: 574px;
        height: 106px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
<style lang="less">
.recordDetail {
}
</style>
